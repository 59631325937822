/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 665px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.024096385542165%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAB/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgED/9oADAMBAAIQAxAAAAE5i1AnKf/EABgQAQEBAQEAAAAAAAAAAAAAAAEAAhIi/9oACAEBAAEFAu7R6cIzBf/EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPwGH/8QAGBABAAMBAAAAAAAAAAAAAAAAAQASIMH/2gAIAQEABj8CgWHmf//EABsQAAIDAAMAAAAAAAAAAAAAAAERACExEEFx/9oACAEBAAE/IST7vVBixdUKxD8g4gWT/9oADAMBAAIAAwAAABBHz//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQMBAT8QtVGH/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERIf/aAAgBAgEBPxC08ZT/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRYXH/2gAIAQEAAT8Qu0CbBde1BMB5Xv6HUrQ5ChEgOpmN9zhk/9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"qantas\"\n        title=\"qantas\"\n        src=\"/static/e606d3b083d0609d3434f11fdb8ea000/0d8e0/qantas.jpg\"\n        srcset=\"/static/e606d3b083d0609d3434f11fdb8ea000/4d5fb/qantas.jpg 166w,\n/static/e606d3b083d0609d3434f11fdb8ea000/558f0/qantas.jpg 333w,\n/static/e606d3b083d0609d3434f11fdb8ea000/0d8e0/qantas.jpg 665w,\n/static/e606d3b083d0609d3434f11fdb8ea000/90334/qantas.jpg 998w,\n/static/e606d3b083d0609d3434f11fdb8ea000/38b44/qantas.jpg 1280w\"\n        sizes=\"(max-width: 665px) 100vw, 665px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "Official logo of Qantas"), "\n", React.createElement(_components.p, null, "This airline began in Queensland in 1920 and is one of the oldest continuously operating airlines in the world. Initially, Qantas operated an airmail service in Australia then gradually expanded and joined up with the British Imperial Airways in 1934. From 1934 to 1967 they were known as Qantas Empire Airways Limited then changed their name in 1967 to what is it now. Qantas is actually an acronym for"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Q"), "ueensland ", React.createElement(_components.strong, null, "A"), "nd ", React.createElement(_components.strong, null, "N"), "orthern ", React.createElement(_components.strong, null, "T"), "erritory ", React.createElement(_components.strong, null, "A"), "erial ", React.createElement(_components.strong, null, "S"), "ervice. They also hold the record for most passengers on a single flight with 673 people on a 747 being evacuated after a cyclone hit Darwin."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
